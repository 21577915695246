import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../Shared/Form/Button'
import Title from '../Shared/Title'
import {useTranslation} from "react-i18next";

const Page404 = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <>
            <div className='flex w-full h-full items-center justify-center'>
                <div className='flex flex-col p-20'>
                    <Title text="404" subtitle={t("404") || " "} />

                    <Button action={ () => navigate(-1) } icon={ faArrowLeft } title={t("back")}/>
                </div>
            </div>
        </>
    )
}

export default Page404