import Title from "../../../Shared/Title";
import Error from "../../../Shared/Form/Error";
import Success from "../../../Shared/Form/Success";
import React, {useState} from "react";
import {useUploadMutation} from "../../../../Redux/Api/Api";
import {usePostSponsorMutation} from "../../../../Redux/Api/Sponsors";
import {useAddSponsorLogoMutation} from "../../../../Redux/Api/Sponsors";
import FilePicker from "../../../Shared/Form/FilePicker";
import Button from "../../../Shared/Form/Button";
import BusyArea from '../../../Shared/BusyArea';
import Form from '../../../Shared/Form/Form';
import {all, max, min} from "../../../../Utils/InValidation";
import Input, {FailedRequirements} from "../../../Shared/Form/Input";
import CalendarInput from "../../../Shared/Form/CalendarInput";
import {useAppSelector} from "../../../../Redux/store";
import {Hydra} from "../../../../Utils/Hydra";
import {SponsorResponse} from "../../../../Redux/Api/Request/Sponsors";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SponsorAddPage = () => {

    const org_id = useAppSelector((s) => s.organisation.id)!;
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [ uploadFile ] = useUploadMutation();
    const [ postSponsor ] = usePostSponsorMutation();
    const [ addSponsorLogo ] = useAddSponsorLogoMutation();

    const [ busy, setBusy ] = useState(false);
    const [ error, setError ] = useState('');
    const [ successMessage, setSuccessMessage ] = useState('');
    const [ fileBlob, setFileBlob ] = useState('');

    const toMYSQLDate = (datestring: string) => {
        const date = new Date(datestring)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }

    const submit = async (data: {
        name: string,
        logo: File,
        activeFrom: string,
        activeUntil: string,

    }) => {
        if(FailedRequirements(data, 'name', 'logo', 'activeFrom', 'activeUntil')) return setError(t ("sponsor:error") || "")
        if(busy) return;
        setBusy(true);
        setError('');
        setSuccessMessage('');

        try {
            const upload_id = await uploadFile({
                file: data.logo
            }).unwrap();


            const outData = {
                name: data.name,
                from: toMYSQLDate(data.activeFrom),
                until: toMYSQLDate(data.activeUntil),
                organisation: org_id
            }

            const sponsor_id = await postSponsor([org_id, outData ]).unwrap();

            const logo_url = await addSponsorLogo({
                sponsor_id: sponsor_id.toString(),
                upload_id
            });

            setSuccessMessage(t('sponsor:sponsorAdded') as string);
            setBusy(false)

            navigate('/dashboard/sponsor/list')
        } catch(e) {
            let err = e as Hydra.Error;
            setError(err['hydra:description']);
            setBusy(false);
        }
    }

    return (
        <>
            <Title text={t('sponsor:title')} subtitle={t('sponsor:subtitle') as string} />
            <Error text={ error } />
            <Success text={ successMessage } />

            <BusyArea busy={ busy }>
                <Form submit={ submit }>
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial=" "
                        placeholder=" "
                        label={t('sponsor:name')}
                        id='name'/>
                    <FilePicker required id='logo' label={t('sponsor:logo')} onChangeBlob={ setFileBlob } accept={['image/*']} />
                    <CalendarInput
                        required
                        label={t('sponsor:activeFrom')}
                        id='activeFrom'/>
                    <CalendarInput
                        required
                        label={t('sponsor:activeUntil')}
                        id='activeUntil'/>
                    <div className='flex mt-5'>
                        <Button title={t('sponsor:save')} />
                    </div>
                </Form>
            </BusyArea>
        </>
    )
}

export default SponsorAddPage
