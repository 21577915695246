import { faArrowRightFromBracket } from '@fortawesome/pro-solid-svg-icons'
import React, {useContext, useEffect, useState} from 'react';
import {OrganisationResponseItem} from '../../../Redux/Api/Request/Organisations';
import Button from '../Form/Button'
import {logout} from "../../../Redux/State/Auth";
import Dropdown from '../Form/Dropdown';
import { useLazyGetOrganisationQuery, useLazyGetOrganisationsQuery } from '../../../Redux/Api/Organisations';
import { JWT } from '../../../Utils/JWT';
import { setOrganisation } from '../../../Redux/State/Organisation';
import { useAppDispatch, useAppSelector } from '../../../Redux/store';
import { useNavigate } from 'react-router-dom';
import { ModalContext } from '../Modal/Modal';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useTranslation} from "react-i18next";

export interface SidebarUserProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
}

const SidebarUser = (props: SidebarUserProps) => {

    const org_id = useAppSelector((s) => s.organisation.id)
    const { t } = useTranslation()

    const modal = useContext(ModalContext)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const organisation_name = JWT.read<string>('organisation_name')
    const full_name = JWT.read<string>('given_name') ? JWT.read<string>('given_name')! + ' ' + JWT.read('family_name')! : ''
    const [ getOrganisations, { data: organisations } ] = useLazyGetOrganisationsQuery()
    const [ getOrganisation ] = useLazyGetOrganisationQuery()
    const [ activeOrganisation, setActiveOrganisation ] = useState<OrganisationResponseItem>();
    const hidden = { hidden: 'true' };

    useEffect(() => {
        if(!JWT.read('organisation_id')) getOrganisations(hidden);
        else setFirstOrg(JWT.read('organisation_id')!)
    }, [])

    useEffect(() => {
        if(!organisations) return

        let organisationId = organisations![0].id;
        if (localStorage) {
            const checkOrganisation = localStorage.getItem('active_organisation');
            if (checkOrganisation) {
                organisationId = checkOrganisation;
            }
        }

        if(!org_id) setFirstOrg(organisationId);
    }, [ organisations ])

    const setFirstOrg = (id: string) => {
        if(id === org_id) return

        localStorage.setItem('active_organisation', id);
        getOrganisation({ id }).then((data) => {
            if(org_id) navigate('/dashboard')

            setActiveOrganisation(data.data!);
            dispatch(setOrganisation(data.data!))
        })
    }

    const setOrg = async (id: string) => {
        if(id === org_id) return
        if (!(await modal.show(t('admin:switchOrganizationWarningTitle'), t('admin:switchOrganizationWarningMessage')))) {
            modal.hide()
            return
        }

        modal.hide()

        localStorage.setItem('active_organisation', id);
        getOrganisation({ id }).then((data) => {
            if(org_id) navigate('/dashboard')

            setActiveOrganisation(data.data!);
            dispatch(setOrganisation(data.data!))
        })
    }


    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <div {...props} className='flex flex-col gap-4 px-6 mt-auto py-1 my-3 relative px-5'>
            <div className='flex flex-col'>
                { organisations && (
                    <Dropdown className='mb-2 mt-5' up
                              onChange={ setOrg }
                              initial={ org_id } label={t('sidebar:organisation')} options={
                        Object.fromEntries(organisations.map((org) => (
                            [ org.id, org.name ]
                        )))
                    } />
                ) }
                { !organisations && activeOrganisation && (
                    <div className='flex text-lg'>{ activeOrganisation.name }</div>
                ) }
                <div className='opacity-60'>{ full_name }</div>
            </div>
            <Button style={{backgroundColor: '#FF0058'}} title={t('sidebar:logout')} action={ modal.withModal({ title: t('modal:title'), body: t('modal:logout')}, handleLogout) } icon={ faArrowRightFromBracket } />
        </div>
    )
}

export default SidebarUser
