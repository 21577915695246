import moment from 'moment'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    useAddCardPhotoMutation,
    useGetBlocksQuery,
    useGetCardQuery,
    usePostActivationLinkEmailMutation,
    usePostBlockMutation,
    usePostUnblockMutation,
    usePutCardMutation, useRemoveCardPhotoMutation,
} from '../../../../Redux/Api/Cards';
import {useGetTypeQuery, usePostTypeMutation} from '../../../../Redux/Api/Types';
import { all, isInt, isPositive, max, min } from '../../../../Utils/InValidation'
import {CardEditItem, CardResponseItem} from '../../../../Redux/Api/Request/Cards'
import { useAppSelector } from '../../../../Redux/store'
import BusyArea from '../../../Shared/BusyArea'
import Card from '../../../Shared/Card/Card'
import Button from '../../../Shared/Form/Button'
import CalendarInput from '../../../Shared/Form/CalendarInput'
import Error from '../../../Shared/Form/Error'
import Form from '../../../Shared/Form/Form'
import Input, { FailedRequirements } from '../../../Shared/Form/Input'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import Pill from '../../../Shared/Form/Pill'
import DropdownInput from '../../../Shared/Form/DropdownInput'
import { Hydra } from '../../../../Utils/Hydra'
import Constants from '../../../../Constants'
import Success from '../../../Shared/Form/Success'
import SuccessAdd from '../../../Shared/Form/SuccessAdd'
import Page404 from '../../Page404'
import Switch from "../../../Shared/Form/Switch";
import {useLazyGetOrganisationSettingsQuery, useLazyGetTypeDataQuery} from "../../../../Redux/Api/Organisations";
import {useTranslation} from "react-i18next";
import ImageInput from "../../../Shared/Form/ImageInput";
import {useUploadMutation} from "../../../../Redux/Api/Api";


import IconSVG from '../../../Assets/icon.svg?component'
import nlflag from '../../../../Assets/nl.svg'
import enflag from '../../../../Assets/en.svg'
import deflag from '../../../../Assets/de.svg'
import esflag from '../../../../Assets/es.svg'
import frflag from '../../../../Assets/fr.svg'
import {InputLabel, MenuItem, Select, responsiveFontSizes} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";
import { Background } from 'victory';
import { Language } from '@mui/icons-material';


const CardEditPage = () => {

    const modalContext = useContext(ModalContext)
    const { id } = useParams()
    const { t } = useTranslation()

    if(!id) return <Page404/>

    const org_id = useAppSelector((s) => s.organisation.id)!
    const [ getOrganisationsSettings, {data: settings} ] = useLazyGetOrganisationSettingsQuery()

    const [ putCard ] = usePutCardMutation()
    const [ postBlock ] = usePostBlockMutation()
    const [ postUnblock ] = usePostUnblockMutation()
    const [ sendActivationLink ] = usePostActivationLinkEmailMutation()
    const [ postType ] = usePostTypeMutation()
    const [ uploadFile ] = useUploadMutation()
    const [ addPhoto ] = useAddCardPhotoMutation()
    const [ removePhoto ] = useRemoveCardPhotoMutation()

    const { data, refetch: refetchCard, isError } = useGetCardQuery({
        id: id!,
    })

    const { data: blocks, refetch: refetchBlocks } = useGetBlocksQuery(id!)
    const [ getOrganisationTypes, { data: orgTypes } ] = useLazyGetTypeDataQuery();

    const [ isBlocked, setIsBlocked ] = useState(false)
    const [ error, setError ] = useState('')
    const [ busy, setBusy ] = useState(false)
    const [ types, setTypes ] = useState<Array<string>>([])
    const [ newType, setNewType ] = useState<string>('')
    const [ check, setCheck ] = useState('')
    const [ successMessage, setSuccessMessage ] = useState('')
    const [ successAddMessage, setSuccessAddMessage] = useState('')
    const [ email, setEmail ] = useState('')
    const [ infinite , setInfinite ] = useState(true)
    const [ photo, setPhoto ] = useState<Blob>()
    const [ deletePhoto, setDeletePhoto ] = useState(false)
    const [ lastAction, setLastAction] = useState('')


    var urlParams = new URLSearchParams(window.location.search);
    var add = urlParams.get('add');
    useEffect(() => {
        if (add) {
            setSuccessAddMessage(t('cards:edit:' + add) as string)
        }
    })

    useEffect(() => {
        if(!blocks) return

        setIsBlocked(blocks.length > 0)
    }, [ blocks ])

    useEffect(() => {
        if(org_id) {
            getOrganisationTypes({
                id: org_id
            })
            getOrganisationsSettings(org_id)
        }
    }, [ org_id ])

    const toMYSQLDate = (datestring: string | number) => {
        const date = new Date(datestring)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }

    const makeUnique = (arr: Array<string>) => {
        const unique = [...new Set(arr)]
        return unique
    }

    useEffect(() => {
        if(!data || !orgTypes) return

        let preTypes = []

        for(let type of data.types) {
            let item = orgTypes.find(x => x.id == type.slice('/api/types/'.length))
            if(item) {
                preTypes.push(item.name)
            }
        }

        setTypes(preTypes)
        setEmail(data.email || '')
    }, [ data, orgTypes ])

    const makeNumberValid = (min: number, max: number, input: number) => {
        if (input >= min && input <= max) return input
        if (input <= min) return min
        if (input >= max) return max
    }

    const submit = async (data: {
        initials: string,
        lastname: string,
        image: File,
        email: string,
        numberOfPeople: number,
        dateOfBirth: string,
        location: string,
        telephoneNumber: string,
        department: string,
        activeFrom: string,
        activeUntil: string,
        membershipId: string,
        type: string,
        countryCode: string,
        discount: string,
        team: string
    }) => {
        if(FailedRequirements(data, 'initials', 'lastname', 'activeFrom', 'membershipId')) return setError(t('modal:error') as string)
        if(busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            if (photo && lastAction === 'upload') {
                const uploadablePhoto = new File([photo], photo.name)

                const upload_id = await uploadFile({
                    file: uploadablePhoto
                }).unwrap()

                await addPhoto({
                    card_id: id,
                    upload_id
                })
            }

            if (deletePhoto && lastAction === 'delete') {
                removePhoto(id)
            }

            const outData: Omit<CardEditItem, 'id' | 'organisationId'> = {
                initials: data.initials,
                lastname: data.lastname,
                membershipId: data.membershipId,
                email: data.email,
                activeFrom: toMYSQLDate(data.activeFrom),
                activeUntil: toMYSQLDate(data.activeUntil === '' ? '9999-01-01' : data.activeUntil),
                dateOfBirth: data.dateOfBirth,
                location: data.location,
                telephoneNumber: data.telephoneNumber,
                department: data.department,
                countryCode: data.countryCode,
                discount: data.discount,
                team: data.team,
                numberOfPeople: settings?.familyCards ? parseInt(String(makeNumberValid(1, settings.maxNumberOfPeople, data.numberOfPeople))) : 1, // typescript moment 🗿
                types: []

            }

            let outTypes = []

            for(let type of types) {
                let item = orgTypes?.find(x => x.name == type)

                if(item) {
                    outData.types.push('/api/types/' + item.id)
                } else {
                    const id = await postType({
                        name: type.trim(),
                        organisation: org_id,
                        id: ''
                    }).unwrap()

                    outData.types.push('/api/types/' + id)
                }
            }

            await putCard({
                id,
                ...outData
            }).unwrap()

            refetchCard()

            setSuccessMessage(t('cards:edit:success') as string)
            window.scrollTo(0, 0)
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)


    }

    const submitBlock = async (data: {
        reason: string,
        from: string,
        until: string,
        infinite: boolean
    }) => {
        if(busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            let d = {
                from : toMYSQLDate(data.from),
                until: toMYSQLDate(data.until),
                reason: data.reason,
                infinite: infinite.toString()
            }

            if (infinite) {
                d = {
                    from: new Date().toLocaleDateString(),
                    until: toMYSQLDate('9999-1-1'),
                    reason: data.reason,
                    infinite: infinite.toString()
                }
            }

            await postBlock([id!, d]).unwrap()
            refetchBlocks()

            setSuccessMessage(t('cards:edit:successBlock') as string)
            window.scrollTo(0, 0)
        } catch(e) {
            setError(t('modal:error') as string)
            window.scrollTo(0, 0)
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const submitUnblock = async () => {
        if(busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await postUnblock(id!).unwrap()
            refetchBlocks()

            setSuccessMessage(t('cards:edit:successUnblock') as string)
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const submitEmail = async () => {
        if(!email) return setError(t('cards:edit:noEmail') as string)
        if(busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await sendActivationLink(id!)
            setSuccessMessage(t('cards:edit:emailSend') + email)
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const copyInviteLink = () => {
        if(!data) return

        copyLink(Constants.INVITE_LINK_PREFIX + data.token)
    }

    const copyLink = async (text: string) => {
        if ('clipboard' in navigator) await navigator.clipboard.writeText(text)
        else document.execCommand('copy', true, text)
    }

    const createNewType = () => {
        if(!newType) return
        const arrayOfTypes = types
        arrayOfTypes.push(newType)

        setTypes(makeUnique(arrayOfTypes))

    }

    const removeType = (type: string) => {
        setTypes(makeUnique(types.filter(t => t != type)))
    }

    const handleInfinite = () => {
        setInfinite(!infinite)
    }

    const handleCrop = (blob: Blob | null) => {
        if (blob) {
            setPhoto(blob)
            setLastAction('upload')
        }
    }

    const handleRemovePhoto = () => {
        setDeletePhoto(true)
        setLastAction('delete')
    }


const [language, setLanguage] = useState('NL');



    if(isError) return <Page404/>
    if(!data) return <LoadingPage/>


    return (
        <>
            <Title text={t('cards:edit:title')} textNotBold/>
            <Error text={ error } />
            <Success text={ successMessage } />
            <SuccessAdd text={ successAddMessage } />
            <Button className='mb-4' action={ submitEmail } title={t('cards:edit:activationMail')} />
            <Card free outline>
                <div className=' font-bold'>Download links</div>
                <div className='flex gap-2'>
                    <span>Google Play Store (Android):</span>
                    <span className='text-accent transition-colors cursor-pointer' onClick={ () => { copyLink(Constants.DOWNLOAD_URL_ANDROID); setCheck('android') } }>{ Constants.DOWNLOAD_URL_ANDROID }</span>
                    <span className='opacity-40'>{t('cards:edit:clickToCopy')}</span>
                </div>
                <div className='mb-2 flex gap-2'>
                    <span>App Store (Apple):</span>
                    <span className='text-accent transition-colors cursor-pointer' onClick={ () => { copyLink(Constants.DOWNLOAD_URL_IOS); setCheck('ios') } }>{ Constants.DOWNLOAD_URL_IOS }</span>
                    <span className='opacity-40'>{t('cards:edit:clickToCopy')}</span>
                </div>
                <div className='font-bold'>{t('cards:edit:activationCode')}</div>
                <div className='flex gap-2'>
                    <span>{t('cards:edit:link')}</span>
                    <span className='text-accent transition-colors cursor-pointer' onClick={ () => { copyInviteLink(); setCheck('invite') } }>{ Constants.INVITE_LINK_PREFIX + data.token }</span>
                    <span className='opacity-40'>{t('cards:edit:clickToCopy')}</span>
                </div>
                <div className='flex gap-2'>
                    <span>{t('cards:edit:code')}</span>
                    <span className='text-accent transition-colors cursor-pointer' onClick={ () => { copyLink(data.token!); setCheck('code') } }>{ data.token }</span>
                    <span className='opacity-40'>{t('cards:edit:clickToCopy')}</span>
                </div>
                <div className='flex gap-2'>
                    <span>{t('cards:edit:use')}</span>
                    <span className='text-accent transition-colors cursor-pointer' onClick={ () => { copyLink(Constants.INVITE_CODE_LINK); setCheck('codelink') } }>{ Constants.INVITE_CODE_LINK }</span>
                    <span className='opacity-40'>{t('cards:edit:clickToCopy')}</span>
                </div>
            </Card>
            <BusyArea busy={ busy }>
                <Form className='!mb-6 mt-6' submit={ submit }>
                    <div className='flex'>
                        <div className='flex-grow'>
                            <Input
                                required
                                initial={ data.initials }
                                invalidator={ all(max(255)) }
                                label={t('cards:list:initials')}
                                id='initials'/>
                            <Input
                                required
                                initial={ data.lastname }
                                invalidator={ all(min(2), max(255)) }
                                label={t('cards:list:lastname')}
                                id='lastname'/>
                            <Input
                                initial={ data.email! }
                                valueChange={ setEmail }
                                label={t('cards:list:email')}
                                id='email'/>
                        </div>
                        {settings?.photo ?
                            <div className='w-[450px] ml-6'>
                                <ImageInput onCrop={handleCrop} onDelete={handleRemovePhoto} image={data.photo}/>
                            </div> : null
                        }
                    </div>
                    {settings?.familyCards ?
                        <Input
                            required
                            type='number'
                            noMinLength
                            //@ts-ignore
                            initial={ data.numberOfPeople }
                            label={t('cards:edit:numberOfPeople')}
                            sublabel={'Max ' + settings.maxNumberOfPeople}
                            id='numberOfPeople'/> : null
                    }
                    <CalendarInput
                        required
                        className='mb-6'
                        initial={ data.activeFrom }
                        label={t('cards:list:activeFrom')}
                        id='activeFrom'/>
                    <CalendarInput
                        className='mb-6'
                        sublabel={t('cards:list:optional') as string}
                        initial={data.activeUntil != '9999-01-01T00:00:00+01:00' ? data.activeUntil : ''}
                        label={t('cards:list:activeUntil')}
                        id='activeUntil'/>
                    {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                    {/*    <DateField*/}
                    {/*        label="Controlled field"*/}
                    {/*        value={data.activeUntil}*/}
                    {/*        // onChange={(newValue) => setValue(newValue)}*/}
                    {/*    />*/}
                    {/*</LocalizationProvider>*/}
                    <Input
                        required
                        initial={ data.membershipId }
                        invalidator={ all(min(2), max(255)) }
                        label={t('cards:list:number')}
                        id='membershipId'/>
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial={ data.dateOfBirth }
                        label={t('cards:list:dateOfBirth')}
                        id='dateOfBirth'/>
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial={ data.location }
                        label={t('cards:list:location')}
                        id='location'/>
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial={ data.telephoneNumber }
                        label={t('cards:list:phone')}
                        id='telephoneNumber'/>
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial={ data.department }
                        label={t('cards:list:department')}
                        id='department'/>

                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial={ data.discount }
                        label={t('cards:list:discount')}
                        id='discount'/>

                    <Input
                        sublabel={t('cards:list:optional') as string}
                        initial={data.team}
                        label={t('cards:list:team')}
                        id='team' />

             <div className= 'flex flex-grow flex-col max-w-1' style={{paddingBottom:35}}>
                <label htmlFor="countryCode">{t('cards:list:countryCode')}</label>

                <Select
                required
                defaultValue={data.countryCode} onChange={(event) => setLanguage(event.target.value)}
                    id="countryCode"
                    name="countryCode"
                >
                   <MenuItem value="NL"><img src={nlflag} alt="Dutch" id="NL" style={{width: 30, height: 30, display : 'inline-block'}} className="mr-3 " />{t('organisation:theme:NL')}</MenuItem>
                    <MenuItem value="EN"><img src={enflag} alt="English" id="EN" style={{width: 30, height: 30, display : 'inline-block'}} className="w-14 h-14 mr-3"/>{t('organisation:theme:ENG')}</MenuItem>
                    <MenuItem value="DE"><img src={deflag} alt="German" id="DE" style={{width: 30, height: 30, display : 'inline-block'}} className="w-14 h-14 mr-3"/>{t('organisation:theme:DE')}</MenuItem>
                    <MenuItem value="FR"><img src={frflag} alt="France" id="FR" style={{width: 30, height: 30, display : 'inline-block'}} className="w-14 h-14 mr-3"/>{t('organisation:theme:FR')}</MenuItem>
                    <MenuItem value="ES"><img src={esflag} alt="Spain" id="ES" style={{height: 20, width: 30, paddingTop: 1, paddingBottom: 1, display : 'inline-block'}} className="w-14 h-14 mr-3"/>{t('organisation:theme:ES')} </MenuItem>

                </Select>
            </div>

                    <Card title={t('cards:list:types')} free outline className='mb-4'>
                        <div className='flex gap-2 mt-5'>
                            { types.map((type, i) => (
                                <Pill key={ i } text={ type } onRemove={ () => removeType(type) }/>
                            )) }
                            { types.length <= 0 && (
                                <Pill text={t('cards:edit:noType')} />
                            ) }
                        </div>
                        <div className='flex gap-2 items-center mt-4'>
                            <DropdownInput onChange={ setNewType } id='type' className='min-w-[20rem]' label={t('cards:edit:select') as string} sublabel={t('cards:edit:newType') as string }  options={(orgTypes?.map((t) => t.name) || []) as string[]} />
                            <Button action={ createNewType } className='mt-5' nosubmit title={t('cards:edit:add')} />
                        </div>
                    </Card>
                    <Button title={t('cards:edit:save')} />
                </Form>
                { isBlocked && (
                    <>
                        <Title text={t('cards:edit:unblockCard')} />
                        <div className='mb-4'>{t('cards:edit:blockedFor')} { blocks![0]?.reason } <span className='opacity-40'>({ moment(blocks![0]?.from).fromNow() })</span></div>
                        <Button className='mb-32' action={ modalContext.withModal({ title: t('modal:title'), body: t('modal:unblock') },  submitUnblock) } title={t('cards:edit:unblock')} />
                    </>
                )}
                { !isBlocked && (
                    <Form className='!mb-96' submit={ submitBlock }>
                        <Title text={t('cards:edit:blockCard')} />
                        <Input
                            required
                            large
                            label={t('cards:edit:reason')}
                            id='reason'/>
                        <input type='checkbox' className='ml-1 h-4 w-4 mb-6 bg-transparent' checked={ infinite } onChange={ handleInfinite } /> <span className='ml-2 opacity-70 text-xl'>{t('cards:edit:blockForever')}</span>
                        { infinite ? (
                            <input type='hidden' value='infinite' name='cardType' />
                        ): (
                            <>
                                <CalendarInput
                                    required
                                    label={t('cards:edit:from')}
                                    id='from'/>
                                <CalendarInput
                                    required
                                    label={t('cards:edit:until')}
                                    id='until'/>
                            </>
                        ) }
                        <Button title={t('cards:edit:block')} />
                    </Form>
                )}
            </BusyArea>
        </>
    )
}

export default CardEditPage
