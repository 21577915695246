import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {usePostOrganisationMutation} from '../../../../Redux/Api/Organisations';
import { Hydra } from '../../../../Utils/Hydra'
import BusyArea from '../../../Shared/BusyArea'
import Button from '../../../Shared/Form/Button'
import Error from '../../../Shared/Form/Error'
import Form from '../../../Shared/Form/Form'
import Input from '../../../Shared/Form/Input'
import Title from '../../../Shared/Title'

const OrganisationCreatePage = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()

    const [ postOrganisation ] = usePostOrganisationMutation()

    const [ busy, setBusy ] = useState(false)
    const [ error, setError ] = useState('')

    const submit = async (data: any) => {
        if(busy) return

        setBusy(true)
        setError('')

        try {
            await postOrganisation(data).unwrap()

            navigate('/dashboard/admin/organisation/list')
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    return (
        <>     
            <Title text={t('organisation:addOrganisation')} textNotBold/>
            <BusyArea busy={ busy }>
                <Form submit={ submit }>
                    <Error text={ error } />
                    <Input
                        required
                        label={t('admin:name')}
                        id='name'/>
                    <Button title={t('admin:addButton')}/>
                </Form>
            </BusyArea>
        </>
    )
}

export default OrganisationCreatePage