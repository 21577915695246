import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {useGetOrganisationsQuery} from '../../../../Redux/Api/Organisations';
import Button from '../../../Shared/Form/Button'
import Column from '../../../Shared/Table/Column'
import Data from '../../../Shared/Table/Data'
import Header from '../../../Shared/Table/Header'
import Row from '../../../Shared/Table/Row'
import Table from '../../../Shared/Table/Table'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'

const OrganisationsList = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const hidden = { hidden: 'false' };

    const { data: organisations, } = useGetOrganisationsQuery(hidden, {
        refetchOnMountOrArgChange: true
    });

    if(!organisations) return <LoadingPage/>

    return (
        <>
            <Title text={t('organisation:organisations')} textNotBold/>
            <Table withSearch withEdit withDelete>
                <Header>
                    <Column name="Id"/>
                    <Column name={t ("admin:name")}/>
                </Header>
                <Data>
                    { organisations.map((org, i) => (
                        <Row
                            key={ i }
                            index={ i }
                            editUrl={ '/dashboard/admin/organisation/edit/' + org.id }
                            data={[ org.id, org.name ]}/>
                    )) }
                </Data>
            </Table>
            <Button action={() => navigate('/dashboard/admin/organisation/create')} className='my-6' title={t ("admin:addOrganization")} icon={ faPlus } iconright />
        </>
    )
}

export default OrganisationsList