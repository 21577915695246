import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
    useAddCardPhotoMutation,
    useGetCardQuery,
    usePostActivationLinkEmailMutation,
    usePostCardMutation,
    usePutCardMutation
} from '../../../../Redux/Api/Cards';
import { all, max, min } from '../../../../Utils/InValidation'
import {CardCreateItem, CardEditItem} from '../../../../Redux/Api/Request/Cards'
import { useAppSelector } from '../../../../Redux/store'
import Button from '../../../Shared/Form/Button'
import CalendarInput from '../../../Shared/Form/CalendarInput'
import Error from '../../../Shared/Form/Error'
import Form from '../../../Shared/Form/Form'
import Input, { FailedRequirements } from '../../../Shared/Form/Input'
import Title from '../../../Shared/Title'
import { Hydra } from '../../../../Utils/Hydra'
import BusyArea from '../../../Shared/BusyArea'
import Switch from '../../../Shared/Form/Switch'
import Pill from "../../../Shared/Form/Pill";
import DropdownInput from "../../../Shared/Form/DropdownInput";
import Card from "../../../Shared/Card/Card";
import Page404 from "../../Page404";
import {useLazyGetOrganisationSettingsQuery, useLazyGetTypeDataQuery} from "../../../../Redux/Api/Organisations";
import {usePostTypeMutation} from "../../../../Redux/Api/Types";
import {useTranslation} from "react-i18next";
import ImageInput from "../../../Shared/Form/ImageInput";
import {useUploadMutation} from "../../../../Redux/Api/Api";

import IconSVG from '../../../Assets/icon.svg?component'
import nlflag from '../../../../Assets/nl.svg'
import enflag from '../../../../Assets/en.svg'
import deflag from '../../../../Assets/de.svg'
import esflag from '../../../../Assets/es.svg'
import frflag from '../../../../Assets/fr.svg'
import {InputLabel, MenuItem, Select, responsiveFontSizes} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";
import { Background, Data } from 'victory';

const CardCreatePage = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()

    const [ postCard ] = usePostCardMutation()
    const [ sendActivationLink ] = usePostActivationLinkEmailMutation()
    const [ types, setTypes ] = useState<Array<string>>([])
    const [ newType, setNewType ] = useState<string>('')
    const [ postType ] = usePostTypeMutation()
    const [ putCard ] = usePutCardMutation()
    const [ uploadFile ] = useUploadMutation()
    const [ addPhoto ] = useAddCardPhotoMutation()
    const [ photo, setPhoto ] = useState<Blob>()
    const [sendChecked, setSendChecked] = useState(false)

    const org_id = useAppSelector((s) => s.organisation.id)!
    const [ getOrganisationsSettings, {data: settings} ] = useLazyGetOrganisationSettingsQuery()

    const [ getOrganisationTypes, { data: orgTypes } ] = useLazyGetTypeDataQuery();

    const [ error, setError ] = useState('')
    const [ busy, setBusy ] = useState(false)

    const toMYSQLDate = (datestring: string) => {
        const date = new Date(datestring)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }

    useEffect(() => {
        if(org_id) {
            getOrganisationTypes({
                id: org_id
            })
            getOrganisationsSettings(org_id)
        }
    }, [ org_id ])

    const makeNumberValid = (min: number, max: number, input: number) => {
        if (input >= min && input <= max) return input
        if (input <= min) return min
        if (input >= max) return max
    }

    const submit = async (data: {
        initials: string,
        lastname: string,
        image: File,
        email: string,
        numberOfPeople: number,
        activeFrom: string,
        activeUntil: string,
        dateOfBirth: string,
        location: string,
        telephoneNumber: string,
        department: string,
        discount: string,
        team: string,
        countryCode: string,
        membershipId: string,
        send: string,
        type: string

    }) => {
        if(FailedRequirements(data, 'initials', 'lastname', 'activeFrom', 'membershipId')) return setError('Er is een fout opgetreden.')
        if(data.activeUntil === null || data.activeUntil === '') {
            data.activeUntil = '9999-01-01'
        }
        if(busy) return

        setBusy(true)
        setError('')

        try {
            const outData: Omit<CardCreateItem, 'id' | 'organisationId'> = {
                initials: data.initials,
                lastname: data.lastname,
                membershipId: data.membershipId,
                email: data.email,
                activeFrom: toMYSQLDate(data.activeFrom),
                activeUntil: toMYSQLDate(data.activeUntil),
                dateOfBirth: data.dateOfBirth,
                location: data.location,
                telephoneNumber: data.telephoneNumber,
                department: data.department,
                discount: data.discount,
                team: data.team,
                countryCode: data.countryCode,
                numberOfPeople: settings?.familyCards ? parseInt(String(makeNumberValid(1, settings.maxNumberOfPeople, data.numberOfPeople))) : 1, // typescript moment 🗿 -Michael Schuiling -Tim Dieters
                types: [],
                emailActivation: data.send == 'true'
            }

            if (hasWhiteSpace(data.email)) {
                setError('Emailadres bevat spaties')
                setBusy(false);
                scrollToTop();
                return;
            }

            for(let type of types) {
                let item = orgTypes?.find(x => x.name == type)

                if(item) {
                    outData.types.push('/api/types/' + item.id)
                } else {
                    const id = await postType({
                        name: type.trim(),
                        organisation: org_id,
                        id: ''
                    }).unwrap()

                    outData.types.push('/api/types/' + id)
                }
            }

            let respData = await postCard([org_id, outData]).unwrap()

            const id = respData.id

            if (photo) {
                const uploadablePhoto = new File([photo], photo.name)

                const upload_id = await uploadFile({
                    file: uploadablePhoto
                }).unwrap()

                await addPhoto({
                    card_id: id,
                    upload_id
                })
            }

            await putCard({
                id,
                ...outData
            }).unwrap()

            setBusy(false)

            var add = "success";
            if (sendChecked) {
                add = "successwithmail"
            }
            var redirectUrl = '/dashboard/cards/edit/' + id + '?add=' + encodeURIComponent(add);
            navigate(redirectUrl);

        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }
    }

    const hasWhiteSpace = (s: string) => {
        return s.indexOf(' ') >= 0;
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const makeUnique = (arr: Array<string>) => {
        const unique = [...new Set(arr)]
        return unique
    }

    const createNewType = () => {
        if(!newType) return
        const arrayOfTypes = types
        arrayOfTypes.push(newType)

        setTypes(makeUnique(arrayOfTypes))

    }

    const removeType = (type: string) => {
        setTypes(makeUnique(types.filter(t => t != type)))
    }

    const handleCrop = (blob: Blob | null) => {
        if (blob) {
            setPhoto(blob)
        }
    }
    const [number, setNumber] = useState('');
    const generateNumber = () => {
        const nummer = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000);
        setNumber(nummer.toString());
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNumber(e.target.value);
    };



    const [language, setLanguage] = useState('NL');

    return (
        <>
            <Title text={t('cards:list:createCard')} textNotBold/>
            <BusyArea busy={ busy }>
                <Form submit={ submit } className='pb-24'>
                    <Error text={ error }/>
                    <div className='flex'>
                        <div className='flex-grow'>
                            <Input
                                required
                                invalidator={ all(max(255)) }
                                label={t('cards:list:initials')}
                                id='initials'/>
                            <Input
                                required
                                invalidator={ all(min(2), max(255)) }
                                label={t('cards:list:lastname')}
                                id='lastname'/>
                            <Input
                                required
                                invalidator={ all(max(255)) }
                                label={t('cards:list:email')}
                                id='email'/>
                        </div>
                        {settings?.photo ?
                            <div className='w-[450px] ml-6'>
                                <ImageInput onCrop={handleCrop}/>
                            </div> : null
                        }
                    </div>

                    {settings?.familyCards ?
                        <Input
                            required
                            type='number'
                            noMinLength
                            initial='1'
                            label={t('cards:edit:numberOfPeople')}
                            sublabel={'Max ' + settings.maxNumberOfPeople}
                            id='numberOfPeople'/> : null
                    }
                    <CalendarInput
                        className='mb-6'
                        required
                        label={t('cards:list:activeFrom')}
                        id='activeFrom'/>

                    <CalendarInput
                        className='mb-6'
                        label={t('cards:list:activeUntil')}
                        sublabel={t('cards:list:optional') as string}
                        id='activeUntil'/>
                    <Input
                        required
                        value={number}
                        onChange={handleInputChange}
                        invalidator={ all(min(2), max(255)) }
                        label={t('cards:list:number')}
                        id='membershipId'/>
                    <button className='position-absolute mt-0'  type="button" onClick={generateNumber}>Genereer Lidnummer</button>
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        label={t('cards:list:dateOfBirth')}
                        id='dateOfBirth'/>
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        label={t('cards:list:location')}
                        id='location'/>
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        label={t('cards:list:phone')}
                        id='telephoneNumber'/>
                    <Input
                        sublabel={t('cards:list:optional') as string}
                        label={t('cards:list:department')}
                        id='department'/>

                    <Input
                        sublabel={t('cards:list:optional') as string}
                        label={t('cards:list:discount')}
                        id='discount'/>

                    <Input
                        sublabel={t('cards:list:optional') as string}
                        label={t('cards:list:team')}
                        id='team' />

                        <div className= 'flex flex-grow flex-col max-w-1' style={{paddingBottom:35}}>

                        <label htmlFor="countryCode">{t('cards:list:countryCode')}</label>

                        <Select
                             required
                            value={language}  onChange={(event) => setLanguage(event.target.value)}
                                 id="countryCode"
                                    name="countryCode"
                        >
                        <MenuItem value="NL"><img src={nlflag} alt="Dutch" id="NL" style={{width: 30, height: 30, display : 'inline-block'}} className="mr-3 " />{t('organisation:theme:NL')}</MenuItem>
                            <MenuItem value="EN"><img src={enflag} alt="English" id="NL" style={{width: 30, height: 30, display : 'inline-block'}} className="w-14 h-14 mr-3"/>{t('organisation:theme:ENG')}</MenuItem>
                            <MenuItem value="DE"><img src={deflag} alt="German" id="NL" style={{width: 30, height: 30, display : 'inline-block'}} className="w-14 h-14 mr-3"/>{t('organisation:theme:DE')}</MenuItem>
                            <MenuItem value="FR"><img src={frflag} alt="France" id="NL" style={{width: 30, height: 30, display : 'inline-block'}} className="w-14 h-14 mr-3"/>{t('organisation:theme:FR')}</MenuItem>
                            <MenuItem value="ES"><img src={esflag} alt="Spain" id="NL" style={{height: 20, width: 30, paddingTop: 1, paddingBottom: 1, display : 'inline-block'}} className="w-14 h-14 mr-3"/>{t('organisation:theme:ES')} </MenuItem>

                        </Select>
                        </div>

                    <Card title={t('cards:list:type')} free outline className='mb-4'>
                        <div className='flex gap-2 mt-5'>
                            { types.map((type, i) => (
                                <Pill key={ i } text={ type } onRemove={ () => removeType(type) }/>
                            )) }
                            { types.length <= 0 && (
                                <Pill text={t('cards:list:noType')} />
                            ) }
                        </div>
                        <div className='flex gap-2 items-center mt-4'>
                            <DropdownInput onChange={ setNewType } id='type' className='min-w-[20rem]' label={t('cards:list:selectType') || " "} sublabel={t('cards:list:selectPrefixType') || " "} options={ orgTypes?.map((t) => t.name) || [] } />
                            <Button action={ createNewType } className='mt-5' nosubmit title={t('cards:list:add')} />
                        </div>
                    </Card>
                    <Switch id='send'  onClick={(value) => setSendChecked(value) } label={t('cards:list:sendMail?')} />
                    <Button title={t('cards:list:create')}/>
                </Form>
            </BusyArea>
        </>
    )
}


export default CardCreatePage
